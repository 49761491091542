"use client"
  import __i18nConfig from '@next-translate-root/i18n'
  import AppDirI18nProvider from 'next-translate/AppDirI18nProvider'
  import { useSearchParams as __useSearchParams, useParams as __useParams } from 'next/navigation'
  import { use as __use, Suspense as __Suspense } from 'react'
  import __loadNamespaces from 'next-translate/loadNamespaces'

  ;
// import { captureException, captureMessage } from '@sentry/nextjs'
import React from 'react';
import { ApiError } from '@/services/api/apiProblem';
function Error({ error, reset }: {
    error: Error;
    reset: () => void;
}) {
    let foundErrors = '';
    if (error instanceof ApiError) {
        const validationErrors = error.getValidationErrors();
        foundErrors = JSON.stringify(validationErrors, null, 2);
        // captureMessage(`ApiError -- ${foundErrors}`)
    }
    else {
        foundErrors = error.message + `. ` + error.stack;
    }
    // captureException(error)
    return (<section className="flex h-full items-center p-16">
      <div className="container mx-auto my-8 flex flex-col items-center justify-center px-5">
        <div className="text-center">
          <h2 className="mb-8 text-4xl font-extrabold">¡Algo salió mal!</h2>

          <p className="text-2xl font-semibold md:text-3xl">Lo sentimos, hay un problema en esta página web.</p>
          <p className="mb-8 mt-4 ">
            Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra página de inicio.
          </p>
          <a rel="noopener noreferrer" href="/" className="bg-primary-700 hover:bg-primary-900 focus:ring-primary-900 mb-2 mr-2 rounded-full px-5 py-2.5 text-center text-xl font-medium text-white focus:outline-none focus:ring-4 ">
            Volver a la página de inicio
          </a>
          <button className="focus:ring-primary-900 mb-2 mr-2 rounded-full bg-white px-5 py-2.5 text-center text-xl font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-4 " onClick={() => reset()}>
            Intentar otra vez
          </button>
        </div>
        <div className="mt-10 max-w-3xl border-2 border-red-800 bg-white p-2">
          <pre className="max-w-3xl whitespace-pre-line">{foundErrors}</pre>
        </div>
      </div>
    </section>);
}


  export default function __Next_Translate_new__194dba5723e__(props) {
    const searchParams = __useSearchParams()
    const params = __useParams()
    const detectedLang = params.lang ?? searchParams.get('lang')

    if (detectedLang === 'favicon.ico') return <Error {...props} />

    if (globalThis.__NEXT_TRANSLATE__ && !detectedLang) return <Error {...props} />

    const lang = detectedLang ?? __i18nConfig.defaultLocale
    const config = {
      ...__i18nConfig,
      locale: lang,
      loaderName: 'client /error',
      pathname: '/[lang]/',
    }

    return (
      <__Suspense fallback={null}>
        <__Next_Translate__child__194dba5723e__ 
          {...props} 
          config={config} 
          promise={__loadNamespaces({ ...config, loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)), })}
         />
      </__Suspense>
    )
  }

  function __Next_Translate__child__194dba5723e__({ promise, config, ...props }) {
    const { __lang, __namespaces } = __use(promise);
    const oldNamespaces = globalThis.__NEXT_TRANSLATE__?.namespaces ?? {};
    globalThis.__NEXT_TRANSLATE__ = { lang: __lang, namespaces: { ...oldNamespaces, ...__namespaces }, config };
    return <Error {...props} />;
  }
